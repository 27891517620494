@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.about-container {
  animation: 600ms ease-in-out 0s forwards fade-in-from-bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 510px;
  min-height: calc(100vh - 169px);
  opacity: 0;
  transform: translateY(100px);
}

.about-container a,
.about-container a:visited {
  color: var(--forest-green);
  text-decoration: none;
}

.about-container a:hover {
  text-decoration: underline;
}

.about-container p {
  margin: 0 0 1.5em;
  line-height: 1.5em;
}

.about-container p:last-of-type {
  margin: 0;
}

.break {
  display: none;
}

.links {
  margin-top: 3em;
}

.divider::before {
  content: ' ・ ';
  color: var(--off-black);
  display: inline-block;
  line-height: 22px;
}

@media screen and (min-width: 320px) {
  .about-container {
    min-height: calc(100vh - 133px);
  }
}

@media screen and (min-width: 500px) {
  .about-container {
    min-height: calc(100vh - 148px);
  }
}

@media screen and (min-width: 570px) {
  .break {
    display: inline-block;
  }
}

@media screen and (min-width: 800px) {
  .about-container {
    min-height: calc(100vh - 156.25px);
  }
}

@media screen and (min-width: 1000px) {  
  .about-container {
    min-height: calc(100vh - 186.25px);
  }
}
