@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.doc-content {
  animation: 600ms ease-in-out 0s forwards fade-in-from-bottom;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 169px);
  opacity: 0;
  transform: translateY(100px);
}

@media screen and (min-width: 320px) {
  .doc-content {
    min-height: calc(100vh - 133px);
  }
}

@media screen and (min-width: 500px) {
  .doc-content {
    min-height: calc(100vh - 148px);
  }
}

@media screen and (min-width: 800px) {
  .doc-content {
    min-height: calc(100vh - 156.25px);
  }
}

@media screen and (min-width: 1000px) {  
  .doc-content {
    min-height: calc(100vh - 186.25px);
  }
}