html {
  font-size: 18px;
}

body {
  /* CSS Variables */
  --default-fonts:'Stone Sans Medium', 'Lucida Grande', 'Verdana', sans-serif;
  --default-fonts-bold:'Stone Sans Semibold', 'Lucida Grande', 'Verdana', sans-serif;
  --default-fonts-italic:'Stone Sans Italic', 'Lucida Grande', 'Verdana', sans-serif;
  --off-black: #3E3E3E;
  --off-black-half-opacity: #3E3E3E80; 
  --forest-green: #1B875B;
  --lime-green: #D8FF8B;
  --border-grey: #BFBFBF;
  --background-grey: #bfbfbf80;

  /* Defaults Styles */
  color: var(--off-black);
  font-family: var(--default-fonts);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a:focus-visible {
  color: var(--forest-green);
}

.content { 
  grid-area: content; 
}

:focus {
  outline-color: var(--lime-green);
}

.links { 
  grid-area: links; 
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.985);
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
}

::selection {
  background: var(--lime-green);
}

.siteContainer {  
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto  auto 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    "header nav"
    "links links"
    "content content";
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 0 15px;
  position: absolute;
  right: 0;
}

@media screen and (min-width: 500px) {
  .siteContainer { 
    padding: 0 30px;
  }
}

@media screen and (min-width: 800px) {
  .siteContainer {  
    grid-template-rows: auto 1fr;
    gap: 0px 30px;
    grid-template-areas:
    "header nav"
    "links content";
  }
}