@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nudge {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translate(0px);
  }
}

@keyframes shake {
  0% {
    transform: translateX(16px);
  }
  20% {
    transform: translateX(-16px);
  }
  40% {
    transform: translateX(8px);
  }
  60% {
    transform: translateX(-8px);
  }
  80% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0px);
  }
}


.access-code-input {
  align-items: center;
  display: flex;
  gap: 15px;
}

.access-code-input button {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
}

.access-code-input button {
  display: flex;
  align-items: center;
}

.access-code-input button svg, 
.access-code-input button svg {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.access-code-input button:hover svg, 
.access-code-input button:hover svg {
  transform: translateX(5px);
}

.access-code-input button:focus svg path,
.access-code-input button:focus svg line, 
.access-code-input button:hover svg path, 
.access-code-input button:hover svg line {
  stroke: var(--forest-green);
}

.access-code-input input {
  caret-color: var(--forest-green);
  color: var(--off-black);
  border: none;
  border-bottom: 1px solid var(--off-black);
  font-size: 2rem;
  letter-spacing: 0.25em;
  outline: none;
  transition: border 150ms ease-in-out, color 150ms ease-in-out;
  width: 100%;
}

.access-code-input input:focus {
  border-bottom: 1px solid var(--forest-green);
  color: var(--forest-green);
}

.access-code-prompt {
  animation: 600ms ease-in-out 0s forwards fade-in-from-bottom;
  display: flex;
  flex-direction: column;
  max-width: 500px; 
  opacity: 0;
  transform: translateY(100px);
  width: 100%;
}

.access-code-prompt label {
 margin-bottom: 15px;
}

.error-message {
  font-size: 15px;
  margin-top: 5px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 169px);
}

.nudge {
  animation: 300ms cubic-bezier(0, 0, 0.2, 1) 0s forwards nudge;
}

.prevent-animation {
  animation-duration: 0s;
}

.shake {
  animation: 300ms linear 0s forwards shake;
}

.tooltip-container {
  display: inline-flex;
  position: relative;
}

.tooltip-icon {
  background: none;
  border: 1px solid var(--forest-green);
  border-radius: 50%;
  color: var(--forest-green);
  cursor: help;
  font-family: var(--default-fonts-bold);
  height: 12px;
  justify-content: center;
  left: -2px;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  top: -5px;
  width: 12px;
  font-size: 10px;
}

.tooltip-icon:focus, 
.tooltip-icon:hover {
  background-color: var(--lime-green);
  outline: none;
}

.tooltip-icon:hover + .tooltip-message,
.tooltip-icon:focus + .tooltip-message {
  opacity: 1;
  bottom: 25px;
}

.tooltip-message {
  background-color: #ffffff;
  border-radius: 4px;
  bottom: 20px;
  box-shadow:
    0px 0px 1.3px rgba(0, 0, 0, 0.016),
    0px 0px 3.2px rgba(0, 0, 0, 0.022),
    0px 0px 6.6px rgba(0, 0, 0, 0.028),
    0px 0px 13.5px rgba(0, 0, 0, 0.034),
    0px 0px 37px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  left: -81px;
  line-height: 1.25;
  opacity: 0;
  padding: 6px 10px;
  position: absolute;
  transition: 
    opacity 300ms cubic-bezier(0.65, 0.05, 0.36, 1), 
    bottom 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
  width: 150px;
}

@media screen and (min-width: 320px) {
  .login {
    min-height: calc(100vh - 133px);
  }
}

@media screen and (min-width: 500px) {
  .login {
    min-height: calc(100vh - 148px);
  }
}

@media screen and (min-width: 800px) {
  .login {
    min-height: calc(100vh - 156.25px);
  }
}

@media screen and (min-width: 1000px) {  
  .login {
    min-height: calc(100vh - 186.25px);
  }
}


