@keyframes slide-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pclub-insert-animation {
  0% {
    transform: translateX(-9%) translateY(15%);
  } 
  20% {
    transform: translateX(7%) translateY(-14%);
  }
  60% {
    transform: translateX(7%) translateY(-14%);
  }
  80% {
    transform: translateX(-9%) translateY(15%);
  }
  100% {
    transform: translateX(-9%) translateY(15%);
  } 
}

.align-items-top {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.border {
  box-sizing: border-box;
  border: 1px solid var(--border-grey);
}

.col {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.col.row-on-mobile {
  flex-direction: row;
}

.col:has(.work-divider) {
  row-gap: 0;
}

.constrain-height,
.constrain-mobile-height {
  height: 500px;
}

.fit-mobile-height,
.grey-bg {
  background-color: var(--background-grey);
}

.fit-mobile-height.image img {
  height: 100%;
  margin: 0 auto;
  width: auto;
}

.hideOnMobile {
  display: none;
}

.image {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.image img {
  width: 100%;
}

.justify-content-bottom{
  justify-content: flex-end;
}

.mobile-alt ~ div {
  display: none;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.row.do-not-collapse {
  flex-direction: row;
}

.row:has(.work-divider) {
  row-gap: 0;
}

.scrollable {
  overflow-y: scroll;
}

.slide-in-from-bottom {
  animation: 1.2s ease-in-out 0s forwards slide-in-from-bottom;
}

.stacked {
  position: absolute;
  top: 0;
}

.stacked-container {
  position: relative;
}

.top-border, .top-border-on-mobile {
  border-top: 1px solid var(--off-black);
  margin-top: 0;
  padding-top: 10px;
} 

video {
  width: 100%;
}

.work-divider-container {
  opacity: 100%;
  transition: opacity 150ms ease-in-out;
}

.work-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 169px);
  pointer-events: none;
}

.work-content.has-doc:has(:hover, :focus-within) .work-divider-container {
  opacity: 50%;
  transition: opacity 150ms ease-in-out;
}

.work-content :is(.col, .row) .work-divider-container:is(:hover, :focus-within) {
  opacity: 100%;
  transition: opacity 150ms ease-in-out;
}

.work-divider {
  margin: 10px 0 30px;
  opacity: 0;
  pointer-events: auto;
  transform: translateY(100vh);
  z-index: 0;
}

.work-divider h2{
  font-family: var(--default-fonts-bold);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.01em;
  margin: 0 0 20px;
}

#csr-pages {
  max-width: 100%;
}

#mykp-mobile {
  background-color: var(--background-grey);
  padding: 0 25%;
}

#pclub-border {
  bottom: 0; 
  height: 77%;
  top: unset;
  width: 100%;
}

#pclub-insert {
  animation: 5s cubic-bezier(0.4, 0, 0.2, 1) 0s infinite pclub-insert-animation;
  scale: 0.9;
}

#pclub-pocket {
  bottom: 1px;
  left: 1px;
  top: unset;
}

#tools-page-01,
#tools-page-02 {
  gap: 0;
  position: relative;
}

#tools-page-01 .image:first-child,
#tools-page-02 .image:first-child {
  border: 1px solid var(--border-grey);
  border-bottom: none;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

#tools-page-01::after,
#tools-page-02::after {
  content: '';
  display: block;
  border-bottom: 1px solid var(--border-grey); 
  width: 100%;
}

#tools-page-01 .image + .image,
#tools-page-02 .image + .image {
  border: 1px solid var(--border-grey); 
  position: absolute;
  right: 0;
  top: 0;
  width: 94.5%;
  z-index: 0;
}

@media screen and (min-width: 320px) {
  .work-content {
    min-height: calc(100vh - 133px);
  }
}

@media screen and (min-width: 500px) {
  .work-content {
    min-height: calc(100vh - 148px);
  }
}

@media screen and (min-width: 800px) {
  .work-content {
    min-height: calc(100vh - 156.25px);
  }
}
  
@media screen and (min-width: 1000px) { 
  .row {
    flex-direction: row;
  }
  
  .col{
    width: unset;
  }

  .col.row-on-mobile {
    flex-direction: column;
  }

  .constrain-mobile-height {
    height: unset;
  }

  .fit-mobile-height.image img {
    width: 100%;
  }

  .hideOnMobile {
    display: flex;
  }

  .mobile-alt {
    display: none;
  }

  .mobile-alt ~ div {
    display: flex;
  }

  .top-border-on-mobile {
    border: none;
    padding-top: 0;
  }

  .w16 {
    width: 16%;
  }
  .w20 {
    width: 20%;
  }
  .w24-6 {
    width: 24.6%;
  }
  .w24-9 {
    width: 24.9%;
  }
  .w27-3 {
    width: 27.3%;
  }
  .w29-6 {
    width: 29.6%;
  }
  .w30-7 {
    width: 30.7%;
  }
  .w31-3 {
    width: 31.3%;
  }
  .w32-2 {
    width: 32.2%;
  }
  .w34-9 {
    width: 34.9%; 
  }
  .w37-4 {
    width: 37.4%
  }
  .w37-9 {
    width: 37.9%
  }
  .w40 {
    width: 40%;
  }
  .w40-4 {
    width: 40.4%;
  }
  .w41-4 {
    width: 41.4%;
  }
  .w41-7 {
    width: 41.7%;
  }
  .w43-6{
    width: 43.6%;
  }
  .w44 {
    width: 44%
  }
  .w45-3 {
    width: 45.3%;
  }
  .w46-4 {
    width: 46.4%;
  }
  .w47-6 {
    width: 47.6%;
  }
  .w51-7 {
    width: 51.7%;
  }
  .w53 {
    width: 53%
  }
  .w53-6 {
    width: 53.6%;
  }
  .w50 {
    width: 50%;
  }
  .w52-7 {
    width: 52.7%;
  }
  .w56-6 {
    width: 56.6%;
  }
  .w56-9 {
    width: 56.9%; 
  }
  .w58-9 {
    width: 58.9%;
  }
  .w59-5 {
    width: 59.5%;
  }
  .w60 {
    width: 60%;
  }
  .w63-2 {
    width: 63.2%;
  }
  .w63-5 {
    width: 63.5%;
  }
  .w65-4 {
    width: 65.4%;
  }
  .w65-8 {
    width: 65.8%;
  }
  .w67-3 {
    width: 67.3%;
  }
  .w68-5 {
    width: 68.5%;
  }
  .w70 {
    width: 70%;
  }
  .w72 {
    width: 72%;
  }
  .w73-2 {
    width: 73.2%;
  }
  .w100 {
    width: 100%;
  }

  .work-content {
    min-height: calc(100vh - 186.25px);
  }
  
  #csr-pages {
    width: calc(200% + 36px);
  }

  #mykp-mobile {
    padding: 0;
  }
}