@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes step-and-fade {
  0% {
    opacity: 50%;
  } 
  50%{
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

.not-found-container {
  animation: 600ms ease-in-out 0s forwards fade-in-from-bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 169px);
  opacity: 0;
  transform: translateY(100px);
}

.not-found-message {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.paw-prints {
  display: flex;
  gap: 10px;
}

.paw {
  animation: 3s infinite step-and-fade;
  display: flex;
  height: 11px;
  opacity: 0;
}

.paw-01 {
  align-self: center;
  transform: rotate(60deg);
}

.paw-02 {
  align-self: flex-end;
  animation-delay: 0.25s;
  transform: rotate(100deg);
}

.paw-03 {
  transform: rotate(75deg);
  animation-delay: 0.5s;
}

@media screen and (min-width: 320px) {
  .not-found-container {
    min-height: calc(100vh - 133px);
  }
}

@media screen and (min-width: 500px) {
  .not-found-container {
    min-height: calc(100vh - 148px);
  }
}

@media screen and (min-width: 800px) {
  .not-found-container {
    min-height: calc(100vh - 156.25px);
  }
}

@media screen and (min-width: 1000px) {  
  .not-found-container {
    min-height: calc(100vh - 186.25px);
  }
}
