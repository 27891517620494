@keyframes slide-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.back-button {
  background: none;
  border: none;
  font-family: var(--default-fonts);
  font-size: 1em;
  margin: 0;
  padding: 0;
}

.back-button:hover,
.next-link a:hover, 
.prev-link a:hover {
  color: var(--forest-green)
}

.cat-footer {
  animation: 1.2s ease-in-out 0s forwards slide-in-from-bottom;
  display: flex;
  height: 50px;
  margin-top: 20px;
  transform: translateY(100px);
}

.cat-container{
  display: flex;
  align-self: flex-end;
  justify-content: center;
  height: 38px;
  width: 100%;
}

.cat-footer-link {
  display: flex;
  width: 100%;
  height: fit-content;
}

.next-link {
  justify-content: end;
}

.next-link a {
  text-align: end;
}

.next-link a, 
.prev-link a {
  color: var(--off-black);
  text-decoration: none;
}

#Eyes {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.look-left {
  transform: translateX(-30px) translateY(-10px);
} 

.look-right {
  transform: translateX(30px) translateY(-10px);
} 

@media screen and (min-width: 1000px) {
  .cat-footer {
    margin-top: 50px;
  }
}