@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(10vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.col {
  opacity: 100%;
  transition: opacity 150ms ease-in-out;
}

.empty {
  height: 0;
}

.home-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 36px;
  pointer-events: none;
}

.home-content:is(:hover, :focus-within) .col {
  opacity: 50%;
  transition: opacity 150ms ease-in-out;
}

.home-content:hover .col:hover, 
.home-content .col:focus-within {
  opacity: 100%;
  transition: opacity 150ms ease-in-out;
}

.work-tile {
  animation: .9s ease-in-out 0s forwards fade-in-from-bottom;
  aspect-ratio: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  pointer-events: auto;
  transform: translateY(10vh);
}

#fb {
  background-color: #1c2b33;
}

#fb svg{
  width: 35%;
}

#kp {
  background-color: #006BA6;
}

#kp svg{
  width: 33%;
}

#mck {
  background-color: #005a8c;
}

#mck svg {
  width: 40%;
}

#personal {
  background-color: black;
}

#viz {
  background-color: #FF0000;
}

#viz svg {
  width: 50%;
}

@media screen and (min-width: 500px) {
  .home-content .row {
    flex-direction: row;
  }
}
