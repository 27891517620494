@font-face{
  font-family:"Stone Sans Medium";
  src:url("../fonts/1461679/319054b4-5a74-4f66-b267-73c8408a260a.woff2") format("woff2"),url("../fonts/1461679/3029f2cc-f47e-41dc-903b-cdc249856088.woff") format("woff");
}
@font-face{
  font-family:"Stone Sans Italic";
  src:url("../fonts/1461681/964034bf-f406-4466-ab06-9f2f6b01e921.woff2") format("woff2"),url("../fonts/1461681/ed8259b5-4f18-4e88-bae4-0776ca0c968c.woff") format("woff");
}
@font-face{
  font-family:"Stone Sans Semibold";
  src:url("../fonts/1461683/4dd6e12a-1ae1-4834-97c3-6994260c1e4f.woff2") format("woff2"),url("../fonts/1461683/eed68953-88fe-43ea-acdb-6b66cfcb7dd9.woff") format("woff");
}