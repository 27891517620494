.myHeader {
  align-items: flex-end;
  background-color: white;
  border-bottom: 1px solid var(--off-black);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  grid-area: header; 
  padding: 15px 0 11px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.myHeader a {
  color: unset;
  text-decoration: unset;
}

.myHeader h1,
.myHeader p {
  margin: 0;
}

.myHeader h1 {
  font-family: var(--default-fonts-bold);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0.01em;
  max-width: 200px;
}

.myHeader p {
  display: none;
  font-size: 0.75em;
  line-height: 1.5;
}

@media screen and (min-width: 320px) {
  .myHeader h1 {
    max-width: none;
  }
}

@media screen and (min-width: 500px) {
  .myHeader {
    padding-top: 30px;
  }
}

@media screen and (min-width: 800px) {
  .myHeader {
    border-bottom: none;
    padding-bottom: 0;
  }

  .myHeader p {
    display: block;
  }
}