.current-page-padding-work,
#nav-link-work:hover ~ .indicator-container {
  padding-left: calc(100% - 170px);
  padding-right: calc(88px);
}

.current-page-padding-about,
#nav-link-about:hover ~ .indicator-container {
  padding-left: calc(100% - 88px);
  padding-right: 0;
}

.indicator {
  width: 100%;
  height: 100%;
  background-color: var(--lime-green);
} 

.indicator-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  box-sizing: border-box;
  transition: padding 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

input#mobile-menu {
  display: none;
}

.mobile-nav {
  align-items: flex-end;
  background-color: #ffffff;
  border-bottom: 1px solid var(--off-black);
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  z-index: 1;
}

.mobile-menu-icon {
  border-bottom: 3px solid var(--lime-green);
  cursor: pointer;
  display: block;
  height: 19px;
  padding: 14px;
  position: absolute;
  transition: border 300ms ease-in-out;
  width: 24px;
}

.mobile-menu-icon .icon-container,
.mobile-menu-icon .icon-container::before,
.mobile-menu-icon .icon-container::after {
  background: #000;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  transition: background ease 200ms, top ease 200ms 200ms, transform ease 200ms;
  width: 24px;
}

.mobile-menu-icon .icon-container {
  left: 14px;
  top: 22px;
}

.mobile-menu-icon .icon-container::before {
  top: -8px;
}

.mobile-menu-icon .icon-container::after {
  top: 8px;
}

.mobile-nav:has(#mobile-menu:checked) + .nav-links {
  top: 63px;
}

.nav-container {
  display: flex;
  background-color: #ffffff;
  grid-area: nav;
  position: sticky;
  top: 0;
  z-index: 1;
}

.nav-links {
  align-items: flex-end;
  background: #ffffff;
  border-bottom: 1px solid var(--off-black);
  display: flex;
  justify-content: flex-end;
  padding-bottom: 14px;
  padding-top: 14px;
  position: absolute;
  top: 0;
  transition: top 400ms cubic-bezier(0.4, 0, 0.2, 1);
  right: 0;
  width: calc(100vw - 30px);
} 

.nav-links a {
  font-family: var(--default-fonts-bold);
  color: var(--off-black);
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 14px;
}

#mobile-menu:checked + .mobile-menu-icon {
  border-bottom: 0px solid var(--lime-green);
}

#mobile-menu:checked + .mobile-menu-icon .icon-container {
  background: transparent;
}

#mobile-menu:checked + .mobile-menu-icon .icon-container::before {
  transform: rotate(45deg);
}

#mobile-menu:checked + .mobile-menu-icon .icon-container::after {
  transform: rotate(-45deg);
}

#mobile-menu:checked + .mobile-menu-icon .icon-container::before,
#mobile-menu:checked + .mobile-menu-icon .icon-container::after {
  top: 0;
  transition: top ease .3s, transform ease .3s .3s;
}


@media screen and (min-width: 500px) {
  .mobile-nav {
    display: none;
  }

  .nav-links, .mobile-nav:has(#mobile-menu:checked) + .nav-links {
    position: relative;
    top: 0;
    width: 100%;
  }
}